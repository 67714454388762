import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .card-title-wrapper {
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: ${(props) => props.theme.cardTitle};
    padding: 11px 20px;
    margin: 0;
    font-family: 'Prompt-Medium', sans-serif;
    color: ${(props) => props.theme.darkBackground};
    font-size: 12px;
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import CardStyled from './styledComponent'

const CardWrapper = (props) => {
  const renderCardTitle = () => {
    return (
      <div className="card-title-wrapper" id={props.titleId}>
        {props.cardTitle}
      </div>
    )
  }

  return (
    <CardStyled className={props.className}>
      {renderCardTitle()}
      {props.children}
    </CardStyled>
  )
}

CardWrapper.propTypes = {
  className: PropTypes.string,
  cardTitle: PropTypes.string.isRequired,
  titleId: PropTypes.string,
  children: PropTypes.element
}

export default CardWrapper
